.campaignes-all-years {
   display: none;
   flex-direction: column;
   max-width: 1120px;
   width: calc(100% - 300px);
   margin-bottom: 200px;
   @media (max-width: 768px) {
      width: calc(100% - 30px);
   }
   @media (max-width: 1024px) {
      width: calc(100% - 60px);
   }
   &.is-active {
      display: flex;
   }
   .campaigne-section {
      display: flex;
      position: relative;
      flex-direction: column;
      .campaigne-year {
         font-size: 72px;
         line-height: 82px;
         height: 90px;
         @media (max-width: 768px) {
            font-size: 36px;
            line-height: 50px;
            height: 50px;
         }
         @media (max-width: 1024px) {
            font-size: 46px;
            height: 70px;
            line-height: 61px;
         }
      }
      &::after {
         content: "";
         position: absolute;
         top: 90px;
         left: 0;
         width: 100%;
         height: 1px;
         background-color: black;
         @media (max-width: 1024px) {
            top: 70px;
         }
      }
   }
}
.campaigne-wrapper {
   display: flex;
   flex-direction: row;
   width: 100%;
   margin-top: 25px;
   gap: 50px;
   margin-bottom: 50px;
   @media (max-width: 768px) {
      flex-direction: column;
      width: 80%;
      margin-left: auto;
   }
   .campaigne-content {
      display: flex;
      flex-direction: column;
      gap: 20px;
      width: 50%;
      @media (max-width: 768px) {
         width: 100%;
      }
      .campaigne-heading {
         max-width: 330px;
      }
      .campaigne-description {
         max-width: 330px;
      }
   }
   .campaigne-media-container {
      width: 50%;
      @media (max-width: 768px) {
         width: 100%;
      }
      img {
         max-width: 100%;
      }
      .video-campaign {
         position: relative;
         width: 100%;
         padding-bottom: 56.25%;
         height: 0;
         iframe {
           position: absolute;
           top: 0;
           left: 0;
           width: 100%;
           height: 100%;
           border: 0;
         }
      }
   }
}