.header-mobile {
   display: flex;
   align-items: center;
   justify-content: space-between;
   position: sticky;
   top: 0;
   height: 64px;
   width: 100%;
   padding: 0 30px;
   background-color: $lightGrey;
   z-index: 99;
   @media (max-width: 768px) {
      padding: 0 15px;
   }
   .logo-container {
      height: 22px;
      img {
         height: 100%;
      }
   }
   .title-mobile-menu {
      display: none;
   }
   .menu-mobile-nav {
      display: none;
      position: fixed;
      top: 64px;
      left: 0;
      height: calc(100vh - 60px);
      width: 100%;
      overflow-y: auto;
      background-color: #FFFFFF;
      z-index: 10;
      width: 100%;
      .menu-nav-links {
         display: flex;
         flex-direction: column;
         padding-top: 80px;
         gap: 50px;
         font-size: 24px;
         margin-bottom: 50px;
         padding: 80px 30px 0 30px;
         height: 90vh;
         justify-content: center;
         @media (max-width: 768px) {
            padding: 80px 15px 0 15px;
         }
         #Contact {
            display: none;
         }
         .nav-mobile-link {
            cursor: pointer;
            position: relative;
            &::after {
               content: "";
               position: absolute;
               width: 100%;
               height: 2px;
               background-color: black;
               bottom: -25px;
               left: 0;
            }
         }
      }
      .menu-nav-footer {
         .social-media {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 20px;
         }
      }
      
   }
   .header-hamburger-container {
      display: flex;
      align-items: center;
      gap: 20px;
      .mobile-humburger {
         display: flex;
         justify-content: center;
         align-items: center;
         height: 20px;
         width: 20px;
         cursor: pointer;
         .burger-icon {
            display: block;
         }
         .close-icon {
            display: none;
         }
      }
   }
   &.is-active {
      position: fixed;
      background-color: #FFFFFF;
      z-index: 99;
      .menu-mobile-nav {
         display: block;
      }
      .header-hamburger-container {
         .mobile-humburger {
            .burger-icon {
               display: none;
            }
            .close-icon {
               display: block;
            }
         }
      }
   }
   @media (min-width: 1024px) {
      display: none;
   }
}

.header-cards-container {
   margin: 20px;
   display: flex;
   flex-direction: column;
   gap: 20px;
   .header-card-big.header-card {
      align-items: flex-start;
      padding: 35px 25px ;
   }
   .header-card {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 20px;
      border-radius: 10px;
      padding: 35px 95px;
      font-size: 14px;
      font-weight: 600;
      background-color: #F5F5F5;
      img {
         height: 18px;
         width: 18px;
      }
   }
}

.is-hidden {
   overflow: hidden;
}