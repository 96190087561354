.block-hero-home {
  height: auto;
  width: 100%;
  .video-wrapper-desktop {
    position: relative;
    width: 100%;
    padding-bottom: 56.25%;
    height: 0;
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 0;
    }
    @media (max-width: 768px) {
      display: none;
   }
  }
  .video-wrapper-mobile {
    display: none;
    position: relative;
    width: 100%;
    padding-bottom: 178%;
    height: 0;
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 0;
    }
    @media (max-width: 768px) {
      display: block;
   }
  }
  
  
}
