h2 {
   font-size: 60px;
   font-weight: 500;
   width: 650px;
   text-transform: uppercase;

   @media (max-width: 768px) {
      font-size: 36px;
      width: auto;
   }
}

h3 {
   font-size: 14px;
   font-weight: 600;
}

.description {
   display: flex;
   justify-content: space-between;
   padding: 120px 50px 65px 50px;
   gap: 40px;
   width: calc(100% - 100px);
   max-width: 1120px;

   @media (max-width: 1023px) {
      flex-direction: column;
      max-width: 660px;
      padding: 20px;
   }

   @media (max-width: 768px) {
      max-width: 450px;
      width: calc(100% - 30px);
      padding: 50px 15px 20px;
   }

   &-title {
      width: 440px;
   }

   &-image {

      @media (max-width: 1023px) {
         display: flex;
         align-items: center;
         justify-content: center;

         img {
            width: 100%;
            max-width: 100%;
         }
      }
   }
}

.description-content-container {
   display: flex;
   flex-direction: column;
   gap: 30px;
   max-width: 660px;

   .description-content {
      font-size: 24px;
      font-weight: 400;
      margin-bottom: 20px;
      max-width: 400px;
      text-align: justify;

      @media (max-width: 768px) {
         font-size: 20px;
      }
   }

   .description-link {

      @media (max-width: 1023px) {
         width: 100%;
      }
   }

   .description-title {
      width: 70%;
   }
}

.description-image {
   border-radius: 5px;
   max-height: 550px;
   max-width: 450px;
   overflow: hidden;

   img {
      width: 100%;
      display: block;
   }
}

.tips-for-quit {
   background-color: #F5F5F5;
   margin: 65px 50px;
   max-width: 1120px;
   width: calc(100% - 100px);
   
   @media (max-width: 768px) {
      max-width: 660px;
      width: calc(100% - 30px);
      padding: 70px 0px;
      margin: 20px;
   }
}

.all-tips {
   display: flex;
   margin-top: 75px;
   margin-left: auto;
   font-size: 18px;

   @media (max-width: 1023px) {
      flex-wrap: wrap;
      row-gap: 15px;
   }

   @media (max-width: 768px) {
      flex-direction: column;
      width: 100%;
   }

   .tips {
      display: flex;
      position: relative;
      flex-direction: column;
      gap: 20px;
      width: calc(100% / 4);
      padding: 20px;
      border-left: 1px solid black;
      border-bottom: 1px solid black;

      @media (max-width: 1023px) {
         width: calc(100% / 2);

         &:nth-child(2) {
            border-right: 1px solid black;
         }
      }

      @media (max-width: 768px) {
         width: 100%;
         border-right: 1px solid black;
      }

      &:last-child {
         border-right: 1px solid black;
      }

      &::after {
         content: "";
         position: absolute;
         height: 15px;
         width: 30px;
         left: 0;
         bottom: -1px;
         transform: translateX(-50%);
         background-color: $lightGrey;

         @media (max-width: 768px) {
            height: 25px;
            bottom: -12px;
            z-index: 2;
         }
      }

      &::before {
         content: "";
         position: absolute;
         height: 15px;
         width: 30px;
         right: 0;
         bottom: -1px;
         transform: translateX(50%);
         background-color: $lightGrey;

         @media (max-width: 768px) {
            height: 25px;
            bottom: -12px;
            z-index: 2;
         }
      }

      p {
         font-weight: 800;
         text-transform: uppercase;
      }

      a {
         margin-top: auto;

         svg {
            margin-left: 10px;

            &.arrow-red {
               display: none;
            }
         }
      }

      a:hover {
         color: $red;
         font-weight: 600;

         .arrow-red {
            display: inline-block;
         }

         .arrow-black {
            display: none;
         }
      }

      svg {
         height: 44px;
      }
   }
}

.informations-resources {
   width: calc(100% - 100px);
   margin: 65px 50px;
   max-width: 1120px;

   @media (max-width: 768px) {
      margin: 0;
      width: 100%;
   }

   h2 {
      margin-bottom: 80px;

      @media (max-width: 768px) {
         margin-left: 20px;
      }
   }

   .all-resources {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 15px;

      @media (max-width: 1023px) {
         grid-template-columns: repeat(3, 1fr);

         h2 {
            grid-column: 1 / 4;
         }
      }

      @media (max-width: 768px) {
         display: flex;
         overflow-x: auto;
         flex-wrap: nowrap;
         width: 100%;
         padding: 0 20px;
         margin: 20px 0;

         &::-webkit-scrollbar {
            display: none;
         }
      }
   }
}

.resource {
   background-color: #FFF;
   border-radius: 5px;
   overflow: hidden;

   &:hover {

      .resource-heading {
         transition: all .3s ease-in-out;
         background-color: $red !important;
         color: white;
         
      }
   }
   .resource-heading {
      height: 125px;
      padding: 22px 25px;
      font-size: 20px;

      &.color-pink {
         background-color: $pink;
      }

      &.color-red {
         background-color: #F2CCCE;
      }

      &.color-mediumGrey {
         background-color: $mediumGrey;
      }

      &.color-mediumRed {
         background-color: #F2CCCE;
      }

      &.color-darkGrey {
         background-color: $darkGrey;
         
      }

      &.color-ligthGrey {
         background-color: $grey;
      }
   }

   .resource-description {
      font-size: 16px;
      line-height: 17px;
      padding: 22px 25px;
      min-height: 185px;
   }

   @media (max-width: 768px) {
      flex: 0 0 270px;
   }
}

.organizations {
   width: calc(100% - 100px);
   margin: 65px 50px;
   max-width: 1120px;

   @media (max-width: 768px) {
      width: calc(100% - 30px);
      margin: 65px 15px;
   }

   &-heading {
      margin-bottom: 70px;

      @media (max-width: 768px) {
         margin-bottom: 30px;
      }
   }

   .all-organizations {
      width: 100%;
      display: flex;
      align-items: flex-end;
      flex-direction: column;

      .organization {
         display: flex;
         flex-direction: row;
         justify-content: space-between;
         align-items: center;
         width: 80%;
         padding: 10px 5px;
         font-size: 24px;
         font-weight: 500;
         border-bottom: 1px solid black;

         @media (max-width: 768px) {
            width: 100%;
            font-size: 16px;

            svg {
               width: 11px;
               height: 11px;
            }
         }

         .svg-red {
            display: none;
         }

         &:hover {
            transition: all .3s ease-in-out;
            color: $red;
            font-weight: 600;

            .svg-red {
               display: block;
            }

            .svg-black {
               display: none;
            }
         }
      }
   }
}

