button, input, optgroup, select, textarea {
  font-family: $fontGeneral;
}

.form-select {
  width: 100%;
  border-radius: 15px;
  padding: 20px 45px 20px 24px;
  font-weight: bold;
  border: none;
  appearance: none;
  outline: none;
  background-image: url("data:image/svg+xml;base64,PHN2ZyBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMCA0Ij48cGF0aCBkPSJNOC41IDEuMjVMNS4wMTggMi45OSAxLjUzNyAxLjI1IiBzdHJva2U9IiM3Mzg3OUEiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+PC9zdmc+");
  background-size: 7px 7px;
  background-repeat: no-repeat;
  background-position: center right 24px;
}

.input-wrapper {
  width: 100%;
  position: relative;

  &:last-child {
    border: 0;
  }

  &.light {
    input, textarea {
      background: $white;
    }
  }

  input, textarea {
    width: 100%;
    border-radius: 15px;
    padding: 27px 24px 10px;
    font-weight: 800;
    appearance: none;
    -webkit-appearance: none;
    border: 0;
    outline: 0;
    transition: all ease-out .2s;
    min-height: 55px;

    &::placeholder {
      opacity: 0;
      transition: opacity ease-out .2s;
    }

    &:not(:placeholder-shown), &:focus {
      & + label {
        top: 6px;
        transform: translateY(0);
      }

      &::placeholder {
        opacity: 1;
      }
    }
  }

  textarea + label {
    top: 20px;
    transform: translateY(0);
  }

  label {
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translateY(-50%);
    font-weight: bold;
    font-size: 14px;
    line-height: 170%;
    color: #6E7191;
    opacity: 1;
    pointer-events: none;
    transition: all ease-out .2s;
  }
}

.conctact-form-wrapper {
  width: 100%;
  max-width: calc(1060px + 48px);
  padding: 0 24px;
  margin: 0 auto;
  padding-bottom: 60px;

  .message.notice {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 15px 20px;
    border-radius: 6px;

    font-family: $fontGeneral;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 40px;
    letter-spacing: 0px;
    text-align: center;

    margin: 0;

    @media (max-width: 767px) {
      font-size: 16px;
      line-height: 22px;
    }

    &.is-none {
      display: none;
    }
  }

  .form-title {
    font-family: $fontGeneral;
    font-style: normal;
    font-weight: bold;
    font-size: 70px;
    line-height: 90px;

    text-align: center;
    letter-spacing: -4px;

    color: #191818;

    margin-bottom: 40px;

    @media (max-width: 1023px) {
      font-size: 60px;
      font-style: normal;
      font-weight: 600;
      line-height: 61px;
      text-align: center;
      margin-left: 0;
      margin-bottom: 20px;

    }

    @media (max-width: 767px) {
      font-size: 40px;
      font-style: normal;
      font-weight: 700;
      line-height: 58px;
      letter-spacing: -3px;
      text-align: center;
      margin-bottom: 20px;

    }
  }

  .contact-form {
    display: flex;
    flex-wrap: wrap;

    input, textarea {
      background-color: #E9E9E9;
      border: none;
      border-radius: 6px;
      padding: 14px 15px;

      &::placeholder {
        font-family: $fontGeneral;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;

        display: flex;
        align-items: center;

        color: #888888;
      }
    }

    input {
      width: 100%;
      margin-bottom: 15px;
      max-width: calc(100% / 2 - 20px);

      &:nth-child(even) {
        margin-right: 40px;
      }

      @media (max-width: 1023px) {
        max-width: calc(100% / 2 - 10px);

        &:nth-child(even) {
          margin-right: 20px;
        }
      }

      @media (max-width: 767px) {
        &:nth-child(even) {
          margin-right: 0px;
        }

        max-width: 100%;
      }
    }

    textarea {
      width: 100%;
      min-height: 120px;
    }

    button {
      width: 100%;
      max-width: 200px;
      margin: 0 auto;
      margin-top: 30px;
      box-shadow: 2px 5px 25px rgba(0, 0, 0, 0.12);
      border: none;
    }
  }
}
