.block-risk-slider {
  padding-top: 40px;
  padding-bottom: 100px;

  @media(max-width: 767px) {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .risk-slider-title {
    font-family: $fontGeneral;
    font-size: 80px;
    font-style: normal;
    font-weight: 700;
    line-height: 100px;
    letter-spacing: -4px;
    text-align: center;
    margin-bottom: 52px;

    @media(max-width: 767px) {
      font-size: 60px;
      line-height: 80px;
      letter-spacing: -3px;
      margin-bottom: 61px;
    }
  }

  .risk-slider-wrapper {

    .flickity-prev-next-button {
      box-shadow: 2px 5px 25px rgba(0, 0, 0, 0.12);

      &.next {
        right: 20%;

        @media (max-width: 1064px) {
          right: 5%;
        }

        @media (max-width: 767px) {
          right: 24px;
        }
      }

      &.previous {
        left: 20%;

        @media (max-width: 1064px) {
          left: 5%;
        }

        @media (max-width: 767px) {
          left: 24px;
        }
      }
    }
    .risk-slide-wrapper {
      width: 100%;
      max-width: calc(100vw / 2);

      @media (max-width: 1064px) {
        max-width: calc(100vw / 2 + 100px);
      }

      @media (max-width: 767px) {
        max-width: calc(100vw - 48px);
      }

      &.is-selected {
        .risk-text {
          opacity: 1;
        }

        .risk-image {
          img {
            transform: scale(1);
            opacity: 1;
          }
        }
      }

      .risk-image {
        margin: 0 auto;
        margin-bottom: 40px;
        max-width: 550px;

        @media (max-width: 767px) {
          max-width: 180px;
        }

        img {
          display: block;
          width: 100%;
          max-width: 100%;

          transform: scale(0.5);
          opacity: 0.5;
        }
      }

      .risk-text {
        display: block;
        max-width: 620px;
        margin: 0 auto;
        opacity: 0;

        @media (max-width: 767px) {
          max-width: 327px;
        }

        p {
          font-family: $fontGeneral;
          font-size: 22px;
          font-style: normal;
          font-weight: 500;
          line-height: 34px;
          letter-spacing: 0px;
          text-align: center;

          @media (max-width: 767px) {
            font-size: 16px;
            line-height: 22px;
          }
        }
      }
    }
  }
}

.no-risk-slider {
  margin-top: -10vw;
}
