.feed-instagram {
    width: calc(100% - 30px);
    margin: 65px 15px;

    @media (min-width: 599px) {
        width: calc(100% - 100px);
        margin: 65px 50px;
        max-width: 1120px;
    }

    &-heading {
        margin-bottom: 30px;
        
        @media (min-width: 767px) {
            margin-bottom: 70px;
        }
    }

    &-listing {
        position: relative;
        overflow-x: auto;
        display: grid;
        grid-template-columns: repeat(6, 300px);
        gap: 20px;
    }

    a {
        display: inline-block;
        height: 300px;
    }

    img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}