.header {
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 50;
  background-color: $white;
  transition: background-color ease-out .2s;


  &.light {
    background-color: transparent;
    position: fixed;

    .header-container {
      .logo-container .logo-link .logo {
        &.light {
          display: block;
        }
        &.dark {
          display: none;
        }
      }

      .nav-links .nav-link,
      .lang-switcher-container .lang-switcher {
        color: $white;
      }

      .lang-switcher-container .lang-switcher {
        background-image: url("data:image/svg+xml;base64,PHN2ZyBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PHBhdGggZD0iTTEyIDE1LjVhMS4wMDIgMS4wMDIgMCAwIDEtLjcxLS4yOWwtNC00YTEuMDA0IDEuMDA0IDAgMCAxIDEuNDItMS40MkwxMiAxMy4xbDMuMy0zLjE4YTEgMSAwIDEgMSAxLjM4IDEuNDRsLTQgMy44NmExIDEgMCAwIDEtLjY4LjI4WiIgZmlsbD0iI2ZmZiIvPjwvc3ZnPg==");
      }

      .mobile-buttons svg path {
        stroke: $white;
      }
    }

    &.is-scrolled {
      background-color: $white;
      position: fixed;

      .header-container {
        .logo-container .logo-link .logo {
          &.light {
            display: none;
          }
          &.dark {
            display: block;
          }
        }

        .nav-links .nav-link,

        .lang-switcher-container .lang-switcher {
          background-image: url("data:image/svg+xml;base64,PHN2ZyBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PHBhdGggZD0iTTEyIDE1LjVhMS4wMDIgMS4wMDIgMCAwIDEtLjcxLS4yOWwtNC00YTEuMDA0IDEuMDA0IDAgMCAxIDEuNDItMS40MkwxMiAxMy4xbDMuMy0zLjE4YTEgMSAwIDEgMSAxLjM4IDEuNDRsLTQgMy44NmExIDEgMCAwIDEtLjY4LjI4WiIgZmlsbD0iIzE5MTgxOCIvPjwvc3ZnPg==");
        }
      }
    }
  }

  @media (max-width: 1023px) {
    display: none;
    &.is-active {
      background-color: $white;

      .header-container {
        .logo-container .logo-link .logo {
          &.light {
            display: none;
          }
          &.dark {
            display: block;
          }
        }

        .header-navigation {
          opacity: 1;
          pointer-events: auto;
        }

        .mobile-buttons {
          .burger-icon {
            display: none;
          }

          .close-icon {
            display: block;
          }
        }
      }
    }
  }

  .header-container {
    width: 100%;
    display: flex;
    align-items: stretch;
    padding: 20px 45px;
    background-color: $lightGrey;
    height: 64px;

    @media (max-width: 1023px) {
      padding: 20px 24px;
    }

    .logo-container {
      flex: 1;
      height: 25px;

      .logo-link {
        height: 25px;

        @media (max-width: 1023px) {
          top: 0;
        }

        .logo {
          height: 25px;
        }
      }
    }

    .header-navigation {
      display: flex;
      align-items: center;

      @media (max-width: 1023px) {
        opacity: 0;
        pointer-events: none;
        position: fixed;
        top: 56px;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: $white;
        flex-direction: column;
        align-items: flex-start;
        padding: 100px 24px 40px;
        transition: opacity ease-out .2s;
      }
    }

    .nav-links {
      display: flex;
      align-items: center;
      justify-content: center;
      align-items: center;
      list-style-type: none;

      @media (max-width: 1023px) {
        flex: 1;
        flex-direction: column;
      }

      .nav-link {
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 120%;
        text-align: center;
        margin:0 25px ;
        color: $grisHeader;
        &:hover {
          transition: all .3s ease-in-out;
          color: black;
        }

        &.is-active {
          color: $red;
          font-weight: bold;
          position: relative;
        }

        @media (max-width: 1023px) {
          margin-bottom: 50px;
          margin-right: 0;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }

    .lang-switcher-container {
      display: flex;
      flex: 1;
      align-items: center;
      flex-direction: row-reverse;
      font-weight: 600;
      color: $grisHeader;
      &:hover {
        transition: all .3s ease-in-out;
        color: black;
      }

      @media (max-width: 1023px) {
        margin-left: 0;
      }

      .lang-switcher {
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 120%;
        text-align: center;
        appearance: none;
        border: none;
        background-color: transparent;
        padding-right: 26px;
        background-image: url("data:image/svg+xml;base64,PHN2ZyBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PHBhdGggZD0iTTEyIDE1LjVhMS4wMDIgMS4wMDIgMCAwIDEtLjcxLS4yOWwtNC00YTEuMDA0IDEuMDA0IDAgMCAxIDEuNDItMS40MkwxMiAxMy4xbDMuMy0zLjE4YTEgMSAwIDEgMSAxLjM4IDEuNDRsLTQgMy44NmExIDEgMCAwIDEtLjY4LjI4WiIgZmlsbD0iIzE5MTgxOCIvPjwvc3ZnPg==");
        background-size: 24px 24px;
        background-position: right center;
        background-repeat: no-repeat;
        cursor: pointer;
        transition: color ease-out .2s, background-image ease-out .2s;
      }
    }

    .mobile-buttons {
      display: none;

      @media (max-width: 1023px) {
        display: flex;
      }

      svg {
        height: 16px;
      }

      .close-icon {
        display: none;
      }
    }
  }
}
