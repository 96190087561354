.hero002 {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 145px;

  @media (max-width: 1023px) {
    padding-top: 80px;
  }

  @media (max-width: 767px) {
    padding-top: 55px;
  }

  .hero002-top {
    @extend .container;
    display: flex;
    flex-direction: column;

    .top-heading {
      font-family: $fontGeneral;
      font-style: normal;
      font-weight: bold;
      font-size: 100px;
      line-height: 144%;
      letter-spacing: -4px;
      color: $white;
      margin: 0 0 20px;

      @media (max-width: 1023px) {
        font-size: 90px;
      }

      @media (max-width: 767px) {
        font-size: 70px;
        line-height: 90px;
        letter-spacing: -3px;
        text-align: left;

      }
    }

    .top-text {
      font-style: normal;
      font-weight: normal;
      font-size: 22px;
      line-height: 155%;
      color: $white;
      margin: 0 0 15px;

      @media (max-width: 1023px) {
        font-size: 16px;
      }
    }
  }

  .hero002-center {
    position: relative;
    width: 100%;
    display: flex;
    padding-bottom: 185px;

    @media (max-width: 767px) {
      margin: 40px 0;

      padding-bottom: 400px;
    }

    .center-image {
      width: 100%;
      height: 35vw;
      min-height: 500px;
      object-fit: cover;
      object-position: center center;
      clip-path: polygon(0 10vw, 100% 0, 100% calc(100% - 10vw), 0 100%);

      @media (max-width: 1023px) {
        min-height: 400px;
      }

      @media (max-width: 767px) {
        min-height: 700px;
      }

      &.mobile {
        display: none;

        @media (max-width: 767px) {
          display: block;
        }
      }

      @media (max-width: 767px) {
        &.desktop {
          display: none;
        }
      }
    }

    .center-content {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 50%;
      z-index: 2;
      transform: translateX(-50%);
      @extend .container;
      font-family: $fontGeneral;
      font-style: normal;
      font-weight: bold;
      font-size: 100px;
      line-height: 144%;
      letter-spacing: -4px;

      @media (max-width: 1023px) {
        font-size: 75px;
      }

      @media (max-width: 767px) {
        font-size: 70px;
        font-style: normal;
        font-weight: 700;
        line-height: 101px;
        letter-spacing: -3px;
      }

      .centre-text-top {
        position: absolute;
        top: 24px;
        left: 24px;
        margin: 0;

        .centre-text-top-title {
          font-family: $fontGeneral;
          font-size: 100px;
          font-style: normal;
          font-weight: 700;
          line-height: 144px;
          letter-spacing: -4px;
          text-align: left;

          @media (max-width: 767px) {
            font-family: $fontGeneral;
            font-size: 70px;
            font-style: normal;
            font-weight: 700;
            line-height: 101px;
            letter-spacing: -3px;
            text-align: left;

          }
        }

        .bottom-text {
          width: 100%;
          max-width: 330px;
          margin: 10px 40px 0 0;
          font-family: $fontGeneral;
          font-style: normal;
          font-weight: bold;
          font-size: 36px;
          line-height: 125%;
          letter-spacing: -3px;
          color: $white;

          @media (max-width: 1023px) {
            font-size: 32px;
            letter-spacing: 0;
            margin: 10px 0 0;
          }

          @media (max-width: 767px) {
            margin: 0 0 10px;
            font-family: $fontGeneral;
            font-size: 36px;
            font-style: normal;
            font-weight: 700;
            line-height: 45px;
            letter-spacing: -3px;
            text-align: left;
          }
        }

        @media (max-width: 1023px) {
          top: 0;
        }

        @media (max-width: 767px) {
          top: -30px
        }
      }

      .centre-text-bottom {
        position: absolute;
        bottom: 24px;
        right: 24px;
        margin: 0;

        @media (max-width: 1023px) {
          bottom: 0;
          width: 100%;
          max-width: 390px;
        }

        @media (max-width: 767px) {
          bottom: -30px;
          left: 24px;
          width: 100%;
          max-width: 100%;
        }

        .centre-text-bottom-title {
          font-family: $fontGeneral;
          font-size: 100px;
          font-style: normal;
          font-weight: 700;
          line-height: 144px;
          letter-spacing: -4px;
          text-align: left;

          @media (max-width: 767px) {
            font-family: $fontGeneral;
            font-size: 70px;
            font-style: normal;
            font-weight: 700;
            line-height: 101px;
            letter-spacing: -3px;
            text-align: left;
          }
        }

        .bottoms-arguments {
          display: flex;

          @media (max-width: 767px) {
            flex-direction: column;
            padding-bottom: 100px;
          }


          .bottom-argument {
            width: 100%;
            max-width: 220px;
            margin-left: 70px;
            display: flex;
            flex-direction: column;

            &:first-child {
              margin-left: 0;
            }

            @media (max-width: 1023px) {
              margin-left: 40px;
            }

            @media (max-width: 767px) {
              max-width: 100%;
              margin-left: 0;
            }

            &.big {
              width: 100%;
              max-width: calc(220px * 2 + 70px);

              .argument-text {
                font-family: $fontGeneral;
                font-style: normal;
                font-weight: bold;
                font-size: 36px;
                line-height: 125%;
                letter-spacing: -3px;

                @media (max-width: 1023px) {
                  font-size: 32px;
                  letter-spacing: 0;
                }

                @media (max-width: 767px) {
                  font-family: $fontGeneral;
                  font-size: 36px;
                  font-style: normal;
                  font-weight: 700;
                  line-height: 45px;
                  letter-spacing: -3px;
                  text-align: left;
                }
              }
            }

            .argument-heading {
              font-family: $fontGeneral;
              font-style: normal;
              font-weight: bold;
              font-size: 70px;
              line-height: 144%;
              letter-spacing: -2px;
              color: $white;
              margin: 0 0 12px;
              text-align: left;

              @media (max-width: 1023px) {
                font-size: 45px;
                margin-bottom: 6px;
              }

              @media (max-width: 767px) {
                max-width: 100%;
                margin: 10px 0 0;
                font-family: $fontGeneral;
                font-size: 70px;
                font-style: normal;
                font-weight: 700;
                line-height: 101px;
                letter-spacing: -3px;
                text-align: left;
              }
            }

            .argument-text {
              font-family: $fontGeneral;
              font-size: 16px;
              font-style: normal;
              font-weight: 500;
              line-height: 22px;
              letter-spacing: 0px;
              text-align: left;

              @media (max-width: 767px) {

                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 22px;
                letter-spacing: 0px;
                text-align: left;

              }
            }
          }
        }
      }
    }
  }
}
