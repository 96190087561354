// --------------------------------------------------
// Variables
// --------------------------------------------------


@font-face {
font-family:"neue-haas-grotesk-display";
src:url("https://use.typekit.net/af/2807c7/00000000000000007735bb48/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/2807c7/00000000000000007735bb48/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/2807c7/00000000000000007735bb48/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
font-display:auto;font-style:normal;font-weight:400;font-stretch:normal;
}

@font-face {
font-family:"neue-haas-grotesk-display";
src:url("https://use.typekit.net/af/1ba16c/00000000000000007735bb5a/30/l?subset_id=2&fvd=n5&v=3") format("woff2"),url("https://use.typekit.net/af/1ba16c/00000000000000007735bb5a/30/d?subset_id=2&fvd=n5&v=3") format("woff"),url("https://use.typekit.net/af/1ba16c/00000000000000007735bb5a/30/a?subset_id=2&fvd=n5&v=3") format("opentype");
font-display:auto;font-style:normal;font-weight:500;font-stretch:normal;
}

@font-face {
font-family:"neue-haas-grotesk-display";
src:url("https://use.typekit.net/af/153042/00000000000000007735bb62/30/l?subset_id=2&fvd=n6&v=3") format("woff2"),url("https://use.typekit.net/af/153042/00000000000000007735bb62/30/d?subset_id=2&fvd=n6&v=3") format("woff"),url("https://use.typekit.net/af/153042/00000000000000007735bb62/30/a?subset_id=2&fvd=n6&v=3") format("opentype");
font-display:auto;font-style:normal;font-weight:600;font-stretch:normal;
}

.tk-neue-haas-grotesk-display { font-family: "neue-haas-grotesk-display",sans-serif; }

// Fonts
$fontGeneral: 'neue-haas-grotesk-display', 'sans-serif';


$black: #140102;
$red: #C30B17;
$lightGrey: #F5F5F5; 
$white: #FFF;
$mediumRed: #D5545D;
$pink: #FFDEE0;
$grey: #E2E2E2;
$mediumGrey: #CFCECE;
$darkGrey: #9B9898;
$lightBlack: #363232;
$grisHeader: #615B5B
