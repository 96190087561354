/*! Flickity v2.1.1
https://flickity.metafizzy.co
---------------------------------------------- */

.flickity-enabled {
  position: relative;
}

.flickity-enabled:focus {
  outline: none;
}

.flickity-viewport {
  overflow: hidden;
  position: relative;
  height: 100%;
}

.flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%;
}

/* draggable */

.flickity-enabled.is-draggable {
  -webkit-tap-highlight-color: transparent;
  tap-highlight-color: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.flickity-enabled.is-draggable .flickity-viewport {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab;
}

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

/* ---- flickity-button ---- */

.flickity-button {
  display: none;
  position: absolute;
  background: hsla(0, 0%, 100%, 0.75);
  border: none;
  color: #333;
}

.flickity-button:hover {
  background: white;
  cursor: pointer;
}

.flickity-button:focus {
  outline: none;
  box-shadow: 0 0 0 5px #19f;
}

.flickity-button:active {
  opacity: 0.6;
}

.flickity-button:disabled {
  opacity: 0.3;
  cursor: auto;
  /* prevent disabled button from capturing pointer up event. #716 */
  pointer-events: none;
}

.flickity-button-icon {
  fill: #333;
}

/* ---- previous/next buttons ---- */

.flickity-prev-next-button {
  top: 50%;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  /* vertically center */
  transform: translateY(-50%);
}

.flickity-prev-next-button.previous {
  left: 10px;
}
.flickity-prev-next-button.next {
  right: 10px;
}
/* right to left */
.flickity-rtl .flickity-prev-next-button.previous {
  left: auto;
  right: 10px;
}
.flickity-rtl .flickity-prev-next-button.next {
  right: auto;
  left: 10px;
}

.flickity-prev-next-button.next::after {
  content: "";
  display: block;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTkiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxOSAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE4LjcwNzEgNy4yOTI4OUMxOS4wOTc2IDcuNjgzNDIgMTkuMDk3NiA4LjMxNjU4IDE4LjcwNzEgOC43MDcxMUwxMi4zNDMxIDE1LjA3MTFDMTEuOTUyNiAxNS40NjE2IDExLjMxOTUgMTUuNDYxNiAxMC45Mjg5IDE1LjA3MTFDMTAuNTM4NCAxNC42ODA1IDEwLjUzODQgMTQuMDQ3NCAxMC45Mjg5IDEzLjY1NjlMMTYuNTg1OCA4TDEwLjkyODkgMi4zNDMxNUMxMC41Mzg0IDEuOTUyNjIgMTAuNTM4NCAxLjMxOTQ2IDEwLjkyODkgMC45Mjg5MzJDMTEuMzE5NSAwLjUzODQwOCAxMS45NTI2IDAuNTM4NDA4IDEyLjM0MzEgMC45Mjg5MzJMMTguNzA3MSA3LjI5Mjg5Wk0xIDlDMC40NDc3MTQgOSAwIDguNTUyMjggMCA4QzAgNy40NDc3MiAwLjQ0NzcxNCA3IDEgN1Y5Wk0xOCA5SDFWN0gxOFY5WiIgZmlsbD0iYmxhY2siLz4KPC9zdmc+Cg==");
  background-position: center center;
  background-repeat: no-repeat;
  appearance: none;
  position: absolute;
  left: 20%;
  top: 20%;
  width: 60%;
  height: 60%;
}

.flickity-prev-next-button.previous::after {
  content: "";
  display: block;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTkiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxOSAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTAuMjkyODkzIDguNzA3MTFDLTAuMDk3NjMxMSA4LjMxNjU4IC0wLjA5NzYzMTEgNy42ODM0MiAwLjI5Mjg5MyA3LjI5Mjg5TDYuNjU2ODUgMC45Mjg5MzJDNy4wNDczOCAwLjUzODQwNyA3LjY4MDU0IDAuNTM4NDA3IDguMDcxMDcgMC45Mjg5MzJDOC40NjE1OSAxLjMxOTQ2IDguNDYxNTkgMS45NTI2MiA4LjA3MTA3IDIuMzQzMTVMMi40MTQyMSA4TDguMDcxMDcgMTMuNjU2OUM4LjQ2MTU5IDE0LjA0NzQgOC40NjE1OSAxNC42ODA1IDguMDcxMDcgMTUuMDcxMUM3LjY4MDU0IDE1LjQ2MTYgNy4wNDczOCAxNS40NjE2IDYuNjU2ODUgMTUuMDcxMUwwLjI5Mjg5MyA4LjcwNzExWk0xOCA3QzE4LjU1MjMgNyAxOSA3LjQ0NzcxIDE5IDhDMTkgOC41NTIyOCAxOC41NTIzIDkgMTggOUwxOCA3Wk0xIDdMMTggN0wxOCA5TDEgOUwxIDdaIiBmaWxsPSJibGFjayIvPgo8L3N2Zz4K");
  background-position: center center;
  background-repeat: no-repeat;
  appearance: none;
  position: absolute;
  left: 20%;
  top: 20%;
  width: 60%;
  height: 60%;
}

.flickity-prev-next-button .flickity-button-icon {
  position: absolute;
  left: 20%;
  top: 20%;
  width: 60%;
  height: 60%;
  display: none;
}

/* ---- page dots ---- */

.flickity-page-dots {
  position: absolute;
  width: 100%;
  bottom: -25px;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  line-height: 1;
}

.flickity-rtl .flickity-page-dots {
  direction: rtl;
}

.flickity-page-dots .dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 8px;
  background: #333;
  border-radius: 50%;
  opacity: 0.25;
  cursor: pointer;
}

.flickity-page-dots .dot.is-selected {
  opacity: 1;
}
