.footer {
  position: relative;
  background-color: $grey;
  padding: 45px 45px 20px 45px;
  display: grid;
  grid-template-columns: repeat(3, 1fr) 20%;
  grid-template-areas: 
  "heading heading heading contact"
  "social social social social"
  "credit credit credit credit";
  margin-top: 200px;
  @media (max-width: 1023px) {
    grid-template-columns: repeat(3, 1fr) 30%;
  }
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    grid-template-areas: 
    "heading"
    "social"
    "contact"
    "credit";
    width: 100%;
    padding: 30px 15px;
  }
  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    background-color: black;
    top: 0;
    left: 0;
  }
  &-heading {
    grid-area: heading;
    color: $darkGrey;
    font-weight: 500;
    font-size: 36px;
    @media (max-width: 1023px) {
      font-size: 24px;
    }
    @media (max-width: 768px) {
      width: 100%;
    }
    strong {
      color: black;
      font-weight: 500;
    }
  }
  &-contact {
    font-size: 16px;
    line-height: 20px;
    grid-area: contact;
    .footer-logo-container {
      margin-bottom: 15px;
      height: 26px;
      img {
        height: 100%;
      }
    }
    @media (max-width: 768px) {
      width: 100%;
   }
  }
  &-social {
    display: flex;
    margin: 100px 0;
    grid-area: social;
    gap: 60px;
    @media (max-width: 768px) {
      width: 100%;
      flex-direction: column;
      margin: 60px 0;
   }
    &-container {
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 260px;
      padding-top: 10px;
      @media (max-width: 768px) {
        width: 100%;
     }
      .footer-social-name {
        font-weight: 600;
        font-size: 24px;
      }
      .container-logo-social {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 36px;
        width: 36px;
        background-color: $red;
        border-radius: 50%;
        color: white;
      }
      &:hover {
        transition: all .3s ease-in-out;
        color: $red;
          
      }
      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: black;
      }
    }
  }
}

.footer-credit {
  display: flex;
  justify-content: space-between;
  grid-area: credit;
  a,p {
    white-space: nowrap;
    font-size: 14px;
    color: $lightBlack;
  }
  a:hover {
    color: $red;
    font-weight: 600;
  }
  @media (max-width: 768px) {
    flex-direction: column-reverse;
    margin-top: 90px;

  }
}