.button {
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 170%;
  text-align: center;
  padding: 10px 30px;
  background-color: $white;
  border-radius: 50px;
  transition: all ease-out 0.2s;
  cursor: pointer;

  // Primary Black
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAwIiBoZWlnaHQ9IjEwMCIgdmlld0JveD0iMCAwIDEwMCAxMDAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik01MCAxMDBDNzcuNjE0MiAxMDAgMTAwIDc3LjYxNDIgMTAwIDUwQzEwMCAyMi4zODU4IDc3LjYxNDIgMCA1MCAwQzIyLjM4NTggMCAwIDIyLjM4NTggMCA1MEMwIDc3LjYxNDIgMjIuMzg1OCAxMDAgNTAgMTAwWiIgZmlsbD0iYmxhY2siLz4KPC9zdmc+Cg==");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 0;
  appearance: none;

  box-shadow: 2px 5px 25px rgba(0, 0, 0, 0.12);

  @media (max-width: 520px) {
    width: 100%;
    padding: 10px 14px;
  }

  &:hover {
    background-size: 110%;
    color: $white;
  }

  &:disabled, &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &.hover-blue {
    // Blue
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAwIiBoZWlnaHQ9IjEwMCIgdmlld0JveD0iMCAwIDEwMCAxMDAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik01MCAxMDBDNzcuNjE0MiAxMDAgMTAwIDc3LjYxNDIgMTAwIDUwQzEwMCAyMi4zODU4IDc3LjYxNDIgMCA1MCAwQzIyLjM4NTggMCAwIDIyLjM4NTggMCA1MEMwIDc3LjYxNDIgMjIuMzg1OCAxMDAgNTAgMTAwWiIgZmlsbD0iI0IxRTdGQyIvPgo8L3N2Zz4K") !important;
  }

  &.small {
    padding: 6px 20px;
  }

  &.outlined {
    background-color: transparent;
    // Blue
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAwIiBoZWlnaHQ9IjEwMCIgdmlld0JveD0iMCAwIDEwMCAxMDAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik01MCAxMDBDNzcuNjE0MiAxMDAgMTAwIDc3LjYxNDIgMTAwIDUwQzEwMCAyMi4zODU4IDc3LjYxNDIgMCA1MCAwQzIyLjM4NTggMCAwIDIyLjM4NTggMCA1MEMwIDc3LjYxNDIgMjIuMzg1OCAxMDAgNTAgMTAwWiIgZmlsbD0iI0IxRTdGQyIvPgo8L3N2Zz4K");

    &.light {
      background-color: transparent;
      // White
      background-image: url("data:image/svg+xml;base64,PHN2ZyBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMDAgMTAwIj48Y2lyY2xlIGN4PSI1MCIgY3k9IjUwIiByPSI1MCIgZmlsbD0iI2ZmZiIvPjwvc3ZnPg==");

    }

    &.custom {
      background-color: transparent;
      // Blue
      background-image: url("data:image/svg+xml;base64,PHN2ZyBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMDAgMTAwIj48Y2lyY2xlIGN4PSI1MCIgY3k9IjUwIiByPSI1MCIgZmlsbD0iIzAwQkNFNyIvPjwvc3ZnPg==");
    }
  }
}

.link-with-arrow {
  position: relative;
  display: flex;
  align-items: center;
  font-weight: 800;

  &:after {
    content: '';
    display: flex;
    margin-left: 8px;
    width: 16px;
    height: 16px;
    background-image: url("data:image/svg+xml;base64,PHN2ZyBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxNiA3Ij48cGF0aCBkPSJNMTIuMTE5IDIuNTA0SDEuMTA5Yy0uNTUgMC0xIC40NS0xIDFzLjQ1IDEgMSAxaDExLjAxdjEuNzljMCAuNDUuNTQuNjcuODUuMzVsMi43OC0yLjc5Yy4xOS0uMi4xOS0uNTEgMC0uNzFsLTIuNzgtMi43OWMtLjMxLS4zMi0uODUtLjA5LS44NS4zNXYxLjh6IiBmaWxsPSIjMDBCQ0U3Ii8+PC9zdmc+");
    background-position: center left 0px;
    background-repeat: no-repeat;
    background-size: 100% auto;
    transition: background-position ease-out .2s;
  }

  &:hover {
    &:after {
      animation: arrow-right 1s ease-out infinite;
    }
  }

  &.is-reversed {
    &:after {
      display: none;
    }

    &:before {
      content: '';
      display: block;
      margin-right: 8px;
      width: 16px;
      height: 16px;
      background-image: url("data:image/svg+xml;base64,PHN2ZyBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxNiA3Ij48cGF0aCBkPSJNMTIuMTE5IDIuNTA0SDEuMTA5Yy0uNTUgMC0xIC40NS0xIDFzLjQ1IDEgMSAxaDExLjAxdjEuNzljMCAuNDUuNTQuNjcuODUuMzVsMi43OC0yLjc5Yy4xOS0uMi4xOS0uNTEgMC0uNzFsLTIuNzgtMi43OWMtLjMxLS4zMi0uODUtLjA5LS44NS4zNXYxLjh6IiBmaWxsPSIjMDBCQ0U3Ii8+PC9zdmc+");
      background-position: center left 0px;
      background-repeat: no-repeat;
      background-size: 100% auto;
      transform: rotate(180deg);
    }

    &:hover {
      &:before {
        animation: arrow-left 1s ease-out infinite;
      }
    }
  }

  @keyframes arrow-right {
    0% {background-position: center left 0px;}
    48% {background-position: center left 16px;}
    49% {opacity: 0;}
    50% {background-position: center left -16px;}
    51% {opacity: 1;}
    100% {background-position: center left 0px;}
  }

  @keyframes arrow-left {
    0% {background-position: center right 0px;}
    48% {background-position: center right -16px;}
    49% {opacity: 0;}
    50% {background-position: center right 16px;}
    51% {opacity: 1;}
    100% {background-position: center right 0px;}
  }
}

.classic {
  background-color: $red;
  width: max-content;
  font-size: 16px;
  line-height: 20px;
  border: none;
  padding: 10px 24px;
  border-radius: 20px;
  color: white;
  font-weight: 600;
  cursor: pointer;
  text-align: center;
  &:hover {
    transition: all .3s ease-in-out;
    background-color: $pink ;
    color: $lightBlack;
  }
}
