.all-related {
   width: calc(100% - 300px);
   max-width: 1120px;
   display: none;
   flex-direction: column;
   align-items: flex-end;
   margin-bottom: 200px;
   @media (max-width: 1024px) {
      width: calc(100% - 60px);
   }
   @media (max-width: 768px) {
      width: calc(100% - 30px);
   }
   &.is-active {
      display: flex;
   }
   .related-line {
      display: flex;
      position: relative;
      flex-direction: row;
      width: 60%;
      justify-content: space-between;
      align-items: center;
      @media (max-width: 1024px) {
         width: 80%;
      }
      @media (max-width: 768px) {
         width: 100%;
      }
      &::after {
         content: "";
         position: absolute;
         bottom: 0;
         left: 0;
         width: 100%;
         height: 1px;
         background-color: black;
      }
      .related-heading {
         font-size: 24px;
         line-height: 32px;
         margin: 10px 0 10px 6px;
         @media (max-width: 768px) {
            font-size: 16px;
            line-height: 20px;
         }
      }
      svg {
         @media (max-width: 768px) {
            height: 11px;
            width: 11px;
         }
      }
      .arrow-red {
         display: none;
      }
      &:hover {
         .related-heading {
            color: $red;
            font-weight: 500;
         }
         .arrow-red {
            display: block;
         }
         .arrow-black {
            display: none;
         }
      }
   }
}