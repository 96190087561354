.title-history-page {
   display: none;
   width: calc(100% - 300px);
   max-width: 1120px;
   margin: 145px 150px 20px 150px;
   font-size: 96px;
   text-transform: uppercase;
   word-spacing: 20px;
   &.is-active {
      display: block;
   }
   @media (max-width: 1024px) {
      font-size: 60px;
      width: calc(100% - 60px);
      margin: 100px 30px 20px 30px;
   }
   @media (max-width: 768px) {
      font-size: 42px;
      width: calc(100% - 30px);
      margin: 100px 15px 20px 15px;
   }
}
.sub-menu {
   display: flex;
   flex-direction: row;
   gap: 10px;
   width: calc(100% - 300px);
   max-width: 1120px;
   @media (max-width: 1024px) {
      width: calc(100% - 60px);
   }
   @media (max-width: 768px) {
      width: calc(100% - 30px);
      justify-content: center;
   }
   .sub-menu-link {
      padding: 10px 24px;
      font-size: 16px;
      line-height: 20px;
      font-weight: 600;
      border: none;
      background-color: $mediumGrey;
      border-radius: 20px;
      color: black;
      cursor: pointer;
      &:hover {
         transition: all .3s ease-in-out;
         background-color: $pink ;
         color: $lightBlack;
      }

      @media (max-width: 768px) {
         width: calc(100% / 3);
         font-size: 14px;
         display: flex;
         justify-content: center;
      }

      &.is-active {
         background-color: $red;
         color: white;
      }
   }
}
.sub-title-container {
   display: none;
   align-items: flex-end;
   flex-direction: column;
   width: calc(100% - 60px);
   max-width: 1120px;
   gap: 20px;
   margin: 50px 30px 130px 30px;

   @media (max-width: 768px) {
      width: calc(100% - 30px);
      margin: 60px 15px 20px 15px;
   }
   &.related {
      .history-subtitle {
         width: 60%;
         @media (max-width: 768px) {
            width: 100%;
         }
      }
      .history-description {
         width: 60%;
         @media (max-width: 768px) {
            width: 100%;
         }
      }
   }

   .history-subtitle {
      width: 50%;
      font-size: 36px;

      @media (max-width: 1024px) {
         width: 60%;
         font-size: 30px;
      }

      @media (max-width: 768px) {
         width: 100%;
         font-size: 24px;
      }
   }
   .history-description {
      width: 50%;
      font-size: 20px;
      @media (max-width: 1024px) {
         width: 60%;
         font-size: 20px;
      }
      @media (max-width: 768px) {
         width: 100%;
         font-size: 16px;
      }
   }
   &.is-active {
      display: flex;
   }
}

.history-all-years {
   display: none;
   flex-direction: column;
   max-width: 1120px;
   width: calc(100% - 300px);

   
   @media (max-width: 1024px) {
      width: calc(100% - 60px);
      margin: 60px 30px 0 30px;
   }
   @media (max-width: 768px) {
      width: calc(100% - 30px);
      margin: 45px 15px 0 15px;
   }

   &.is-active {
      display: flex;
   }
   .history-container-year {
      position: relative;
      display: flex;
      flex-direction: column;
      .history-year {
         font-size: 72px;
         line-height: 82px;
         height: 90px;

         
         @media (max-width: 1024px) {
            font-size: 46px;
            height: 70px;
            line-height: 61px;
         }
         @media (max-width: 768px) {
            font-size: 36px;
            height: 50px;
            line-height: 41px;
         }

      }
      &::after {
         content: "";
         position: absolute;
         top: 90px;
         left: 0;
         width: 100%;
         height: 1px;
         background-color: black;
         @media (max-width: 1024px) {
            top: 70px;
         }
         @media (max-width: 768px) {
            top: 50px;
         }
      }
   }
}

.history-card {
   position: relative;
   display: flex;
   flex-direction: column;
   gap: 20px;
   width: 50%;
   margin-left: auto;
   padding: 20px 0;
   margin-bottom: 90px;
   @media (max-width: 768px) {
      width: 80%;
   }
   &:not(:first-child) {
      &::before {
         content: "";
         position: absolute;
         top: 0;
         left: 0;
         width: 100%;
         height: 1px;
         background-color: black;
      }
   }
   .history-tag {
      position: absolute;
      top: -18px;
      right: 0;
      transform: translateY(-100%);
      padding: 3px 10px;
      border-radius: 11px;
      background-color: #363232;
      font-size: 14px;
      line-height: 16px;
      color: white;
      @media (max-width: 768px) {
         top: -8px;
      }
   }
   &-heading {
      font-size: 16px;
      font-weight: 600;
   }
   &-description {
      font-size: 16px;
      font-weight: 500;
   }
   &-linkSource {
      color: $red;
      font-weight: 600;
      svg {
         margin-left: 8px;
      }
   }

}

.arrow-scroll-up {
   position: fixed;
   right: 50px;
   bottom: 100px;
   z-index: 10;
   cursor: pointer;
   @media (max-width: 1024px) {
      height: 52px;
      width: 52px;
      right: 15px;
   }
}

