.shop {
   width: 100%;
   display: flex;
   flex-direction: column;
   align-items: center;
}

.hero-image {
   position: relative;
   width: calc(100% - 100px);
   margin: 0px 50px 50px 50px;
   overflow: hidden;
   @media (max-width: 768px) {
      width: calc(100% - 30px);
      margin: 0;
   }
   .hero-image-mobile {
      display: none;
      @media (max-width: 768px) {
         display: block;
      }
   }
   .hero-image-desktop {
      @media (max-width: 768px) {
         display: none;
      }
   }
   .block-student {
      position: absolute;
      display: flex;
      flex-direction: column;
      gap: 20px;
      bottom: 60px;
      left: 45px;
      padding: 22px;
      background-color: white;
      border-radius: 10px;
      width: 380px;
      @media (max-width: 1024px) {
         position: relative;
         bottom: auto;
         left: auto;
         width: 60%;
         margin-top: 15px;
         margin: 15px auto;
      }
      @media (max-width: 768px) {
         position: relative;
         bottom: auto;
         left: auto;
         width: 100%;
         margin-top: 15px;
         background-color: $grey;
         font-size: 14px;
         line-height: 16px;
         a {
            width: 100%;
            text-align: center;
         }
      }
   }
   h1 {
      position: absolute;
      top: 40px;
      left: 45px;
      text-transform: uppercase;
      max-width: 40%;
      font-size: 96px;
      @media (max-width: 768px) {
         top: 17px;
         left: 15px;
         font-size: 42px;
         max-width: 60%;
      }
      @media (max-width: 1024px) {
         font-size: 42px;
      }
   }
   img {
      width: 100%;
      display: block;
   }
}
.all-articles {
   display: flex;
   flex-direction: row;
   flex-wrap: wrap;
   width: calc(100% - 100px);
   gap: 18px;
   margin: 0 50px 50px 50px;
   @media (max-width: 768px) {
      overflow-x: auto;
      flex-wrap: nowrap;
      width: 100%;
      padding: 0 15px;
      margin: 0;
      &::-webkit-scrollbar {
         display: none;
      }
   }
   .article {
      width: calc(33% - 12px);
      @media (max-width: 1024px) {
         width: calc(50% - 9px);
      }
      @media (max-width: 768px) {
         flex: 0 0 325px;
      }
      .image-article-container {
         width: 100%;
         overflow: hidden;
         transition: all .3s ease-in-out;
         &:hover {
            .back {
               display: block;
            }
            .front {
               display: none;
            }
         }
         .back {
            display: none;
         }
         img {
            display: block;
            width: 100%;
         }
      }
   }
}
.button-shop {
   margin-top: 80px;
   @media (max-width: 768px) {
      width: calc(100% - 30px);
      margin: 70px 0 170px 0;
   }
}