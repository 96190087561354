.news-section {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   margin: 35px 7%;
   width: calc(100% - 14%);
   max-width: 1120px;
   @media (max-width: 768px) {
      margin-top: 40px;
      margin: 35px 0;
      width: 100%;
   }
}
.hero-news {
   position: relative;
   width: 100%;
   height: 500px;
   @media (max-width: 768px) {
      width: calc(100% - 30px);
      height: 300px;
   }
   .circle {
      position: absolute;
      height: 430px;
      width: 430px;
      top: 20px;
      left: 40%;
      border-radius: 50%;
      background-color: white;
      @media (max-width: 768px) {
         width: 190px;
         height: 190px;
         top: 55px;
         left: 130px;
      }
      .cross {
         position: absolute;
         height: 400px;
         width: 400px;
         top: 63px;
         left: 190px;
         @media (max-width: 768px) {
            width: 170px;
            height: 170px;
            top: 25px;
            left: 80px;

         }
         &::before {
            content: "";
            position: absolute;
            width: 4px;
            height: 400px;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            background-color: $red;
            @media (max-width: 768px) {
               height: 170px;
            }
         }
         &::after {
            content: "";
            position: absolute;
            width: 400px;
            height: 4px;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            background-color: $red;
            @media (max-width: 768px) {
               width: 170px;
            }
         }
      }
   }
}
.all-news {
   display: flex;
   flex-direction: row;
   flex-wrap: wrap;
   gap: 20px;
   width: 100%;
   @media (max-width: 768px) {
      display: flex;
      overflow-x: auto;
      flex-wrap: nowrap;
      width: 100%;
      padding: 0 20px;
      margin: 20px 0;
      &::-webkit-scrollbar {
         display: none;
      }
   }
   &.is-active-mobile {
      flex-direction: column;
      width: calc(100%);
      padding: 0 15px;
      .news {
         flex: auto;
         width: 100%;
      }
   }
}
.news {
   background-color: white;
   display: flex;
   width: calc(33% - 15px);
   border-radius: 10px;
   overflow: hidden;
   @media (max-width: 1024px) {
      width: calc(50% - 10px);
   }
   @media (max-width: 768px) {
      flex: 0 0 325px;
   }
   &:hover {
      img {
         scale: 1.05;
      }
   }
}

.news-image-container {
   width: 100%;
   height: 265px;
   overflow: hidden;
   position: relative;
   img {
      object-fit: cover;
      min-width: 100%;
      min-height: 100%;
      width: 100%;
      transition-duration: 0.3s;
   }
}
.news-categorie {
   position: absolute;
   padding: 3px 10px;
   border-radius: 11px;
   top: 16px;
   left: 13px;
   background-color: $lightBlack;
   font-size: 14px;
   line-height: 16px;
   color: white;
   z-index: 10;
}
.news-content-container {
   display: flex;
   flex-direction: column;
   padding: 30px;
   height: 240px;
   .news-date {
      font-size: 14px;
      font-weight: 600;
      line-height: 17px;
      color: $darkGrey;
      margin-bottom: 30px;
   }
   .news-name-source {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;  
      width: 100%;
      overflow: hidden;
      font-size: 20px;
      line-height: 28px;
      color: black;
      font-weight: 600;
      margin-bottom: 5px;
      text-transform: none;
   }
   .news-description {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      font-size: 16px ;
      font-weight: 500;
      line-height: 21px;
   }
   .news-link {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 15px;
      color: $red;
      font-size: 14px;
      margin-top: auto;
      span {
         font-weight: 600 !important;
      }
   }
}

.news-mainTitle {
   position: relative;
   z-index: 2;
   font-size: 96px;
   margin-top: 150px;
   text-transform: uppercase;
   @media (max-width: 768px) {
      margin-bottom: 40px;
      font-size: 42px;
      margin-top: 90px;
   }
}

.show-more-news {
   margin-top: 80px;
   width: calc(33% - 15px);
   @media (max-width: 768px) {
      display: none;

   }
}
.show-less-news {
   margin-top: 20px;
   width: calc(33% - 15px);
   @media (max-width: 768px) {
      display: none;
   }
}
.show-more-news-mobile {
   display: none;
   @media (max-width: 768px) {
      display: block;
      margin-top: 30px;
      width: calc(100% - 40px);
   }
}