.block-other-arguments {
  padding: 100px 0;

  @media (max-width: 1023px) {
    padding: 40px 0;
  }

  @media (max-width: 767px) {
    padding: 20px 0;
  }

  .other-arguments-container {
    @extend .container;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 1023px) {
      flex-direction: column;
      align-items: flex-start;
    }

    @media (max-width: 767px) {
      align-items: center;
    }

    .other-arguments-title {
      flex: 1;
      font-family: $fontGeneral;
      font-size: 80px;
      font-style: normal;
      font-weight: 700;
      line-height: 125%;
      letter-spacing: -4px;
      text-align: left;
      margin-bottom: 50px;

      @media (max-width: 1023px) {
        width: 100%;
        font-size: 55px;
      }

      @media (max-width: 767px) {
        font-size: 38px;
        letter-spacing: -2px;
        text-align: center;
        margin-bottom: 40px;
      }
    }

    .other-arguments-wrapper {
      width: 65%;
      display: flex;
      justify-content: stretch;
      align-items: flex-end;

      @media (max-width: 1023px) {
        width: 100%;
      }

      @media (max-width: 767px) {
        flex-direction: column;
        align-items: center;
      }

      .argument-content-container {
        width: calc((100% - 60px) / 2);
        margin-right: 60px;

        &:last-child {
          margin-right: 0;
        }

        @media (max-width: 767px) {
          width: 100%;
          margin-right: 0;
          max-width: 350px;
          margin-bottom: 40px;
        }

        .argument-content-wrapper {
          width: 100%;
          position: relative;
          display: flex;
          flex-direction: column;

          &.fonctionnement, &.how-it-works {
            .argument-content .argument-title {
              font-size: 25px;

              @media (max-width: 767px) {
                font-size: 30px;
              }
            }
          }

          img {
            display: block;
            width: 100%;
            height: 100%;
          }

          .argument-content {
            z-index: 2;
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            width: 100%;
            max-width: 66%;

            @media (max-width: 1023px) {
              align-items: center;
            }

            .argument-btn {
              width: 100%;
              margin: 20px auto 0;
            }

            .argument-title {
              width: 100%;
              font-family: $fontGeneral;
              font-size: 32px;
              font-style: normal;
              font-weight: 700;
              line-height: 40px;
              letter-spacing: 0;
              text-align: left;
              margin: 0 auto;
              color: $white;

              @media (max-width: 767px) {
                max-width: 200px;
                font-size: 40px;
                line-height: 52px;
                letter-spacing: -3px;
                text-align: center;
              }
            }
          }
        }
      }
    }
  }
}
