.block-case-social {
  position: relative;
  clip-path: polygon(0 0, 100% 10vw, 100% 100%, 0 calc(100% - 10vw));

  .block-educative-case {
    padding: 200px 0 100px;
    position: relative;


    @media (max-width: 1023px) {
      padding: 150px 0 80px;
    }

    @media (max-width: 767px) {
      padding: 80px 0 20px;
    }

    &:after {
      content: '';
      position: absolute;
      bottom: -1px;
      left: 0;
      right: 0;
      height: 10vw;
      clip-path: polygon(0 100%, 100% 0, 100% 100%);
    }

    .block-case-container {
      @extend .container;
      display: flex;
      justify-content: space-between;
      position: relative;
      z-index: 2;

      @media (max-width: 767px) {
        flex-direction: column-reverse;
      }

      .case-content-wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        .case-title {
          font-family: $fontGeneral;
          font-size: 70px;
          font-style: normal;
          font-weight: 700;
          line-height: 90px;
          letter-spacing: -4px;
          text-align: center;
          margin-bottom: 20px;

          @media (max-width: 1023px) {
            font-size: 60px;
            line-height: 1.3;
          }

          @media (max-width: 767px) {
            font-size: 40px;
            font-style: normal;
            font-weight: 700;
            line-height: 58px;
            letter-spacing: -3px;
            text-align: center;
          }
        }

        .case-text {
          width: 100%;
          font-family: $fontGeneral;
          font-size: 24px;
          font-style: normal;
          font-weight: 500;
          line-height: 40px;
          letter-spacing: 0px;
          text-align: center;
          margin-bottom: 30px;

          @media (max-width: 767px) {
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 22px;
            letter-spacing: 0px;
            text-align: center;
          }

          p {
            margin: 0;
          }
        }

        .case-video-wrapper {
          width: 100%;
          max-width: 620px;
          display: flex;
          flex-direction: column;
          align-items: flex-end;

          .video-container {
            position: relative;
            width: 100%;
            padding-top: 56.25%;
            margin-bottom: 40px;

            iframe {
              position: absolute;
              inset: 0;
              width: 100% !important;
              height: 100% !important;
            }
          }

          .case-btn {
            width: 100%;
            max-width: 200px;

            @media (max-width: 767px) {
              max-width: 100%;
            }
          }
        }
      }
    }
  }

  .block-case {
    padding: 0 0 110px;
    position: relative;


    @media (max-width: 1023px) {
      padding: 0 0 80px;
    }

    @media (max-width: 767px) {
      padding: 0 0 20px;
    }

    &:after {
      content: '';
      position: absolute;
      bottom: -1px;
      left: 0;
      right: 0;
      height: 10vw;
      clip-path: polygon(0 0, 100% 100%, 0 100%);
    }

    .block-case-container {
      @extend .container;
      display: flex;
      justify-content: space-between;
      position: relative;
      z-index: 2;

      @media (max-width: 767px) {
        flex-direction: column-reverse;
      }

      .case-content-wrapper {
        width: 100%;
        max-width: 620px;

        @media (max-width: 1023px) {
          max-width: 420px;
        }

        @media (max-width: 767px) {
          max-width: 100%;
        }

        .case-title {
          font-family: $fontGeneral;
          font-size: 70px;
          font-style: normal;
          font-weight: 700;
          line-height: 90px;
          letter-spacing: -4px;
          text-align: left;
          margin-bottom: 20px;

          @media (max-width: 1023px) {
            font-size: 60px;
            line-height: 1.3;
          }

          @media (max-width: 767px) {
            font-size: 40px;
            font-style: normal;
            font-weight: 700;
            line-height: 58px;
            letter-spacing: -3px;
            text-align: center;
          }
        }

        .case-text {
          font-family: $fontGeneral;
          font-size: 24px;
          font-style: normal;
          font-weight: 500;
          line-height: 40px;
          letter-spacing: 0px;
          text-align: left;
          margin-bottom: 30px;


          @media (max-width: 767px) {
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 22px;
            letter-spacing: 0px;
            text-align: center;
          }

          p {
            margin: 0;
          }
        }

        .case-btn {
          max-width: 200px;

          @media (max-width: 767px) {
            max-width: 100%;
          }
        }
      }

      .case-image {
        width: 100%;
        max-width: 260px;

        @media (max-width: 767px) {
          max-width: 120px;
          margin: 0 auto;
          margin-bottom: 30px;
        }

        img {
          display: block;
          width: 100%;
        }
      }
    }
  }

  .block-socials {
    padding-top: 0;
    padding-bottom: 150px;
    position: relative;

    @media (max-width: 767px) {
      padding-bottom: 100px;
      padding-top: 40px;
    }

    .block-socials-container {
      @extend .container;

      position: relative;
      z-index: 2;
      .socials-content-wrapper {
        .socials-heading-wrapper {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 20px;

          @media (max-width: 767px) {
            flex-direction: column;
          }

          .socials-title {
            font-family: $fontGeneral;
            font-size: 70px;
            font-style: normal;
            font-weight: 700;
            line-height: 90px;
            letter-spacing: -4px;
            text-align: center;
            margin-left: 55px;

            @media (max-width: 1023px) {
              font-size: 60px;
              font-style: normal;
              font-weight: 600;
              line-height: 61px;
              text-align: center;
              margin-left: 0;
              margin-bottom: 20px;

            }

            @media (max-width: 767px) {
              font-size: 40px;
              font-style: normal;
              font-weight: 700;
              line-height: 58px;
              letter-spacing: -3px;
              text-align: center;
              margin-bottom: 20px;

            }

          }

          img {
            display: block;
            width: 100%;
            max-width: 75px;

            @media (max-width: 767px) {
              max-width: 80px;
              margin-bottom: 10px;


            }
          }
        }

        .socials-btn {
          max-width: 200px;
          margin: 0 auto;
          display: none;

          @media (max-width: 767px) {
            display: flex;
            max-width: 100%;

            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: 22px;
            letter-spacing: 0em;

          }
        }

        .socials-text {
          display: block;
          width: 100%;
          font-family: $fontGeneral;
          font-size: 24px;
          font-style: normal;
          font-weight: 500;
          line-height: 40px;
          letter-spacing: 0px;
          text-align: center;
          margin-bottom: 30px;

          @media (max-width: 767px) {
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 22px;
            letter-spacing: 0px;
            text-align: center;
          }


          p {
            margin: 0;
          }
        }
      }
    }
  }
}
